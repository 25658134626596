@import url(https://fonts.googleapis.com/css2?family=WindSong&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&family=Spectral&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla&family=Playfair+Display&family=Spectral&display=swap);
/* leaf animations */

@media only screen and (max-width: 991px) {
  #leaves {
    display: none;
  }
}

#leaves {
  position: relative;
  top: -50px;
  width: 100%;
  text-align: right;
}

#leaves i {
  display: inline-block;
  width: 200px;
  height: 150px;
  background: linear-gradient(to bottom right, #ca87b3, #e6d8dd);
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  border-radius: 5% 40% 70%;
  box-shadow: inset 0px 0px 1px #222;
  border: 1px solid #333;
  z-index: -1;
  animation: falling 10s 0s infinite;
  -webkit-animation: falling 10s 0s infinite;
}

#leaves i:nth-of-type(2n) {
  animation: falling2 10s 0s infinite;
  -webkit-animation: falling2 10s 0s infinite;
}
#leaves i:nth-of-type(3n) {
  animation: falling3 10s 0s infinite;
  -webkit-animation: falling3 10s 0s infinite;
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  display: block;
  -webkit-transform: rotate(125deg);
          transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius: 50%;
}

#leaves i:nth-of-type(n) {
  height: 23px;
  width: 30px;
}
#leaves i:nth-of-type(n):before {
  width: 7px;
  height: 5px;
  top: 17px;
  right: 1px;
}
#leaves i:nth-of-type(n):after {
  width: 2px;
  height: 17px;
  left: 12px;
  top: 0px;
}

#leaves i:nth-of-type(2n + 1) {
  height: 11px;
  width: 16px;
}
#leaves i:nth-of-type(2n + 1):before {
  width: 4px;
  height: 3px;
  top: 7px;
  right: 0px;
}
#leaves i:nth-of-type(2n + 1):after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 1px;
}

#leaves i:nth-of-type(3n + 2) {
  height: 17px;
  width: 23px;
}
#leaves i:nth-of-type(3n + 2):before {
  height: 4px;
  width: 4px;
  top: 12px;
  right: 1px;
}
#leaves i:nth-of-type(3n + 2):after {
  height: 10px;
  width: 2px;
  top: 1px;
  left: 8px;
}

#leaves i:nth-of-type(n) {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
}
#leaves i:nth-of-type(2n) {
  animation-delay: 3.9s;
  -webkit-animation-delay: 3.9s;
}
#leaves i:nth-of-type(3n) {
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}
#leaves i:nth-of-type(4n) {
  animation-delay: 4.4s;
  -webkit-animation-delay: 4.4s;
}
#leaves i:nth-of-type(5n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leaves i:nth-of-type(6n) {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
}
#leaves i:nth-of-type(7n) {
  animation-delay: 2.8s;
  -webkit-animation-delay: 2.8s;
}
#leaves i:nth-of-type(8n) {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
#leaves i:nth-of-type(9n) {
  animation-delay: 3.3s;
  -webkit-animation-delay: 3.3s;
}
#leaves i:nth-of-type(10n) {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
#leaves i:nth-of-type(11n) {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#leaves i:nth-of-type(12n) {
  animation-delay: 4.1s;
  -webkit-animation-delay: 4.1s;
}
#leaves i:nth-of-type(13n) {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#leaves i:nth-of-type(14n) {
  animation-delay: 4.7s;
  -webkit-animation-delay: 4.7s;
}
#leaves i:nth-of-type(15n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leaves i:nth-of-type(16n) {
  animation-delay: 3.8s;
  -webkit-animation-delay: 3.8s;
}
#leaves i:nth-of-type(17n) {
  animation-delay: 7.8s;
  -webkit-animation-delay: 7.8s;
}
#leaves i:nth-of-type(18n) {
  animation-delay: 4.6s;
  -webkit-animation-delay: 4.6s;
}
#leaves i:nth-of-type(19n) {
  animation-delay: 8.8s;
  -webkit-animation-delay: 8.8s;
}
#leaves i:nth-of-type(20n) {
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}
#leaves i:nth-of-type(21n) {
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
}
#leaves i:nth-of-type(22n) {
  animation-delay: 5.6s;
  -webkit-animation-delay: 5.6s;
}
#leaves i:nth-of-type(23n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leaves i:nth-of-type(24n) {
  animation-delay: 6.6s;
  -webkit-animation-delay: 6.6s;
}
#leaves i:nth-of-type(25n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leaves i:nth-of-type(26n) {
  animation-delay: 2.4s;
  -webkit-animation-delay: 2.4s;
}
#leaves i:nth-of-type(27n) {
  animation-delay: 8.1s;
  -webkit-animation-delay: 8.1s;
}
#leaves i:nth-of-type(28n) {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}
#leaves i:nth-of-type(29n) {
  animation-delay: 9.4s;
  -webkit-animation-delay: 9.4s;
}
#leaves i:nth-of-type(30n) {
  animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

#leaves i:nth-of-type(n) {
  background: linear-gradient(to bottom right, #d4c4cb, #aa4586);
}
#leaves i:nth-of-type(2n + 2) {
  background: linear-gradient(to bottom right, #e0d0d8, #aa4586);
}
#leaves i:nth-of-type(4n + 1) {
  background: linear-gradient(to bottom right, #cebfc5, #aa4586);
}

#leaves i:nth-of-type(n) {
  opacity: 0.9;
}
#leaves i:nth-of-type(3n + 1) {
  opacity: 0.7;
}
#leaves i:nth-of-type(3n + 2) {
  opacity: 0.5;
}

#leaves i:nth-of-type(n) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

#leaves i:nth-of-type(n) {
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
            transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 175vh, 0) rotate(90deg);
            transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
            transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
            transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
            transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 150vh, 0) rotate(0deg);
            transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  #name {
    padding-top: 15%;
    font-family: 'WindSong', cursive;
    font-size: 45px;
    text-shadow: 5px 5px 10px black;
    color: #fed7e2;
  }

  #subtitle {
    margin-top: 10px;
    font-family: 'Major Mono Display', monospace;
    font-size: 12px;
    text-shadow: 3px 3px 3px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 20px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.8);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 20px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
    text-align: center;
  }

  #projectText {
    font-size: 15px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  #gridItem {
    margin-left: 5%;
    margin-right: 5%;
  }

  .branches {
    background-image: url(/static/media/lbranches.e7260474.png),
      url(/static/media/rbranches.7a154238.png);
    background-position: left top, right top;
    background-repeat: repeat, repeat;
  }

  #home {
    background-size: 500px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 400px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    display: none;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 30px;
    text-align: center;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 20px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
  }

  #textTab {
    font-family: 'Karla', sans-serif;
    margin-left: 5%;
    text-align: left;
    color: #ffffff;
  }
}

@media only screen and (min-width: 600px) {
  #name {
    padding-top: 20%;
    font-family: 'WindSong', cursive;
    font-size: 60px;
    text-shadow: 5px 5px 10px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 15px;
    text-shadow: 3px 3px 3px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    background: rgba(57, 66, 80, 0.8);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 25px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
    text-align: center;
  }

  #projectText {
    font-size: 18px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  #gridItem {
    margin-left: 8%;
    margin-right: 8%;
  }

  .branches {
    background-image: url(/static/media/lbranches.e7260474.png),
      url(/static/media/rbranches.7a154238.png);
    background-position: left top, right top;
    background-repeat: repeat, repeat;
  }

  #home {
    background-size: 900px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 110%;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    font-size: 15px;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    display: none;
  }

  #projectSection {
    margin-top: 40px;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 35px;
    text-align: center;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 25px;
  }

  #text {
    margin-top: 50px;
    margin-left: 8%;
    margin-right: 8%;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
  }

  #textTab {
    margin-left: 8%;
    font-family: 'Karla', sans-serif;
    text-align: left;
    color: #ffffff;
  }

  #grid {
    margin-left: 8%;
  }
}

@media only screen and (min-width: 768px) {
  #name {
    padding-top: 170px;
    font-family: 'WindSong', cursive;
    font-size: 100px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 28px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 30px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 20px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  .branches {
    background-image: url(/static/media/lbranches.e7260474.png),
      url(/static/media/rbranches.7a154238.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1100px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 800px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    color: #fed7e2;
    display: inline;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 40px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 30px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 992px) {
  #name {
    padding-top: 250px;
    font-family: 'WindSong', cursive;
    font-size: 100px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 30px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 15px 15px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 40px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 22px;
    text-align: left;
    font-family: 'Karla', sans-serif;
  }

  .branches {
    background-image: url(/static/media/lbranches.e7260474.png),
      url(/static/media/rbranches.7a154238.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1500px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 1100px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    margin-top: 250px;
    color: #fed7e2;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 30px;
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 50px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 35px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
    font-size: 18px;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 1200px) {
  #name {
    padding-top: 270px;
    font-family: 'WindSong', cursive;
    font-size: 170px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 40px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 15px 15px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 2em;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 1.2em;
    text-align: left;
    font-family: 'Karla', sans-serif;
  }

  .branches {
    background-image: url(/static/media/lbranches.e7260474.png),
      url(/static/media/rbranches.7a154238.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1950px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    /* background-size: 1600px; */
    background-size: 78.5%;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    margin-top: 250px;
    color: #fed7e2;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 20%;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 60px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 40px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
    font-size: 20px;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}

/* leaf animations */

#leavesleft {
  position: relative;
  top: -80px;
  width: 100%;
  text-align: left;
}

#leavesleft i {
  display: inline-block;
  width: 200px;
  height: 150px;
  background: linear-gradient(to bottom right, #ca87b3, #e6d8dd);
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  border-radius: 5% 40% 70%;
  box-shadow: inset 0px 0px 1px #222;
  border: 1px solid #333;
  z-index: -1;
  animation: falling 10s 0s infinite;
  -webkit-animation: falling 10s 0s infinite;
}

#leavesleft i:nth-of-type(2n) {
  animation: falling2 10s 0s infinite;
  -webkit-animation: falling2 10s 0s infinite;
}
#leavesleft i:nth-of-type(3n) {
  animation: falling3 10s 0s infinite;
  -webkit-animation: falling3 10s 0s infinite;
}

#leavesleft i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  display: block;
  -webkit-transform: rotate(125deg);
          transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius: 50%;
}

#leavesleft i:nth-of-type(n) {
  height: 23px;
  width: 30px;
}
#leavesleft i:nth-of-type(n):before {
  width: 7px;
  height: 5px;
  top: 17px;
  right: 1px;
}
#leavesleft i:nth-of-type(n):after {
  width: 2px;
  height: 17px;
  left: 12px;
  top: 0px;
}

#leavesleft i:nth-of-type(2n + 1) {
  height: 11px;
  width: 16px;
}
#leavesleft i:nth-of-type(2n + 1):before {
  width: 4px;
  height: 3px;
  top: 7px;
  right: 0px;
}
#leavesleft i:nth-of-type(2n + 1):after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 1px;
}

#leavesleft i:nth-of-type(3n + 2) {
  height: 17px;
  width: 23px;
}
#leavesleft i:nth-of-type(3n + 2):before {
  height: 4px;
  width: 4px;
  top: 12px;
  right: 1px;
}
#leavesleft i:nth-of-type(3n + 2):after {
  height: 10px;
  width: 2px;
  top: 1px;
  left: 8px;
}

#leavesleft i:nth-of-type(n) {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
}
#leavesleft i:nth-of-type(2n) {
  animation-delay: 3.9s;
  -webkit-animation-delay: 3.9s;
}
#leavesleft i:nth-of-type(3n) {
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}
#leavesleft i:nth-of-type(4n) {
  animation-delay: 4.4s;
  -webkit-animation-delay: 4.4s;
}
#leavesleft i:nth-of-type(5n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leavesleft i:nth-of-type(6n) {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
}
#leavesleft i:nth-of-type(7n) {
  animation-delay: 2.8s;
  -webkit-animation-delay: 2.8s;
}
#leavesleft i:nth-of-type(8n) {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
#leavesleft i:nth-of-type(9n) {
  animation-delay: 3.3s;
  -webkit-animation-delay: 3.3s;
}
#leavesleft i:nth-of-type(10n) {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
#leavesleft i:nth-of-type(11n) {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#leavesleft i:nth-of-type(12n) {
  animation-delay: 4.1s;
  -webkit-animation-delay: 4.1s;
}
#leavesleft i:nth-of-type(13n) {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#leavesleft i:nth-of-type(14n) {
  animation-delay: 4.7s;
  -webkit-animation-delay: 4.7s;
}
#leavesleft i:nth-of-type(15n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leavesleft i:nth-of-type(16n) {
  animation-delay: 3.8s;
  -webkit-animation-delay: 3.8s;
}
#leavesleft i:nth-of-type(17n) {
  animation-delay: 7.8s;
  -webkit-animation-delay: 7.8s;
}
#leavesleft i:nth-of-type(18n) {
  animation-delay: 4.6s;
  -webkit-animation-delay: 4.6s;
}
#leavesleft i:nth-of-type(19n) {
  animation-delay: 8.8s;
  -webkit-animation-delay: 8.8s;
}
#leavesleft i:nth-of-type(20n) {
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}
#leavesleft i:nth-of-type(21n) {
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
}
#leavesleft i:nth-of-type(22n) {
  animation-delay: 5.6s;
  -webkit-animation-delay: 5.6s;
}
#leavesleft i:nth-of-type(23n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leavesleft i:nth-of-type(24n) {
  animation-delay: 6.6s;
  -webkit-animation-delay: 6.6s;
}
#leavesleft i:nth-of-type(25n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leavesleft i:nth-of-type(26n) {
  animation-delay: 2.4s;
  -webkit-animation-delay: 2.4s;
}
#leavesleft i:nth-of-type(27n) {
  animation-delay: 8.1s;
  -webkit-animation-delay: 8.1s;
}
#leavesleft i:nth-of-type(28n) {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}
#leavesleft i:nth-of-type(29n) {
  animation-delay: 9.4s;
  -webkit-animation-delay: 9.4s;
}
#leavesleft i:nth-of-type(30n) {
  animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

#leavesleft i:nth-of-type(n) {
  background: linear-gradient(to bottom right, #d4c4cb, #aa4586);
}
#leavesleft i:nth-of-type(2n + 2) {
  background: linear-gradient(to bottom right, #e0d0d8, #aa4586);
}
#leavesleft i:nth-of-type(4n + 1) {
  background: linear-gradient(to bottom right, #cebfc5, #aa4586);
}

#leavesleft i:nth-of-type(n) {
  opacity: 0.9;
}
#leavesleft i:nth-of-type(3n + 1) {
  opacity: 0.7;
}
#leavesleft i:nth-of-type(3n + 2) {
  opacity: 0.5;
}

#leavesleft i:nth-of-type(n) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

#leavesleft i:nth-of-type(n) {
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
            transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 175vh, 0) rotate(90deg);
            transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
            transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
            transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
            transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 150vh, 0) rotate(0deg);
            transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}

