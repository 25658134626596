/* leaf animations */

@media only screen and (max-width: 991px) {
  #leaves {
    display: none;
  }
}

#leaves {
  position: relative;
  top: -50px;
  width: 100%;
  text-align: right;
}

#leaves i {
  display: inline-block;
  width: 200px;
  height: 150px;
  background: linear-gradient(to bottom right, #ca87b3, #e6d8dd);
  transform: skew(20deg);
  border-radius: 5% 40% 70%;
  box-shadow: inset 0px 0px 1px #222;
  border: 1px solid #333;
  z-index: -1;
  animation: falling 10s 0s infinite;
  -webkit-animation: falling 10s 0s infinite;
}

#leaves i:nth-of-type(2n) {
  animation: falling2 10s 0s infinite;
  -webkit-animation: falling2 10s 0s infinite;
}
#leaves i:nth-of-type(3n) {
  animation: falling3 10s 0s infinite;
  -webkit-animation: falling3 10s 0s infinite;
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius: 50%;
}

#leaves i:nth-of-type(n) {
  height: 23px;
  width: 30px;
}
#leaves i:nth-of-type(n):before {
  width: 7px;
  height: 5px;
  top: 17px;
  right: 1px;
}
#leaves i:nth-of-type(n):after {
  width: 2px;
  height: 17px;
  left: 12px;
  top: 0px;
}

#leaves i:nth-of-type(2n + 1) {
  height: 11px;
  width: 16px;
}
#leaves i:nth-of-type(2n + 1):before {
  width: 4px;
  height: 3px;
  top: 7px;
  right: 0px;
}
#leaves i:nth-of-type(2n + 1):after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 1px;
}

#leaves i:nth-of-type(3n + 2) {
  height: 17px;
  width: 23px;
}
#leaves i:nth-of-type(3n + 2):before {
  height: 4px;
  width: 4px;
  top: 12px;
  right: 1px;
}
#leaves i:nth-of-type(3n + 2):after {
  height: 10px;
  width: 2px;
  top: 1px;
  left: 8px;
}

#leaves i:nth-of-type(n) {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
}
#leaves i:nth-of-type(2n) {
  animation-delay: 3.9s;
  -webkit-animation-delay: 3.9s;
}
#leaves i:nth-of-type(3n) {
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}
#leaves i:nth-of-type(4n) {
  animation-delay: 4.4s;
  -webkit-animation-delay: 4.4s;
}
#leaves i:nth-of-type(5n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leaves i:nth-of-type(6n) {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
}
#leaves i:nth-of-type(7n) {
  animation-delay: 2.8s;
  -webkit-animation-delay: 2.8s;
}
#leaves i:nth-of-type(8n) {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
#leaves i:nth-of-type(9n) {
  animation-delay: 3.3s;
  -webkit-animation-delay: 3.3s;
}
#leaves i:nth-of-type(10n) {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
#leaves i:nth-of-type(11n) {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#leaves i:nth-of-type(12n) {
  animation-delay: 4.1s;
  -webkit-animation-delay: 4.1s;
}
#leaves i:nth-of-type(13n) {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#leaves i:nth-of-type(14n) {
  animation-delay: 4.7s;
  -webkit-animation-delay: 4.7s;
}
#leaves i:nth-of-type(15n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leaves i:nth-of-type(16n) {
  animation-delay: 3.8s;
  -webkit-animation-delay: 3.8s;
}
#leaves i:nth-of-type(17n) {
  animation-delay: 7.8s;
  -webkit-animation-delay: 7.8s;
}
#leaves i:nth-of-type(18n) {
  animation-delay: 4.6s;
  -webkit-animation-delay: 4.6s;
}
#leaves i:nth-of-type(19n) {
  animation-delay: 8.8s;
  -webkit-animation-delay: 8.8s;
}
#leaves i:nth-of-type(20n) {
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}
#leaves i:nth-of-type(21n) {
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
}
#leaves i:nth-of-type(22n) {
  animation-delay: 5.6s;
  -webkit-animation-delay: 5.6s;
}
#leaves i:nth-of-type(23n) {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#leaves i:nth-of-type(24n) {
  animation-delay: 6.6s;
  -webkit-animation-delay: 6.6s;
}
#leaves i:nth-of-type(25n) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#leaves i:nth-of-type(26n) {
  animation-delay: 2.4s;
  -webkit-animation-delay: 2.4s;
}
#leaves i:nth-of-type(27n) {
  animation-delay: 8.1s;
  -webkit-animation-delay: 8.1s;
}
#leaves i:nth-of-type(28n) {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}
#leaves i:nth-of-type(29n) {
  animation-delay: 9.4s;
  -webkit-animation-delay: 9.4s;
}
#leaves i:nth-of-type(30n) {
  animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

#leaves i:nth-of-type(n) {
  background: linear-gradient(to bottom right, #d4c4cb, #aa4586);
}
#leaves i:nth-of-type(2n + 2) {
  background: linear-gradient(to bottom right, #e0d0d8, #aa4586);
}
#leaves i:nth-of-type(4n + 1) {
  background: linear-gradient(to bottom right, #cebfc5, #aa4586);
}

#leaves i:nth-of-type(n) {
  opacity: 0.9;
}
#leaves i:nth-of-type(3n + 1) {
  opacity: 0.7;
}
#leaves i:nth-of-type(3n + 2) {
  opacity: 0.5;
}

#leaves i:nth-of-type(n) {
  transform: rotate(180deg);
}

#leaves i:nth-of-type(n) {
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes falling {
  0% {
    transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 175vh, 0) rotate(90deg);
    opacity: 0;
  }
}

@keyframes falling3 {
  0% {
    transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 200vh, 0) rotate(-70deg);
    opacity: 0;
  }
}

@keyframes falling2 {
  0% {
    transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 150vh, 0) rotate(0deg);
    opacity: 0;
  }
}
