@import url('https://fonts.googleapis.com/css2?family=WindSong&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Spectral&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&family=Playfair+Display&family=Spectral&display=swap');

@media only screen and (max-width: 600px) {
  #name {
    padding-top: 15%;
    font-family: 'WindSong', cursive;
    font-size: 45px;
    text-shadow: 5px 5px 10px black;
    color: #fed7e2;
  }

  #subtitle {
    margin-top: 10px;
    font-family: 'Major Mono Display', monospace;
    font-size: 12px;
    text-shadow: 3px 3px 3px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 20px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.8);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 20px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
    text-align: center;
  }

  #projectText {
    font-size: 15px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  #gridItem {
    margin-left: 5%;
    margin-right: 5%;
  }

  .branches {
    background-image: url('Branches/lbranches.png'),
      url('Branches/rbranches.png');
    background-position: left top, right top;
    background-repeat: repeat, repeat;
  }

  #home {
    background-size: 500px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 400px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    display: none;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 30px;
    text-align: center;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 20px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
  }

  #textTab {
    font-family: 'Karla', sans-serif;
    margin-left: 5%;
    text-align: left;
    color: #ffffff;
  }
}

@media only screen and (min-width: 600px) {
  #name {
    padding-top: 20%;
    font-family: 'WindSong', cursive;
    font-size: 60px;
    text-shadow: 5px 5px 10px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 15px;
    text-shadow: 3px 3px 3px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    background: rgba(57, 66, 80, 0.8);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 25px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
    text-align: center;
  }

  #projectText {
    font-size: 18px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  #gridItem {
    margin-left: 8%;
    margin-right: 8%;
  }

  .branches {
    background-image: url('Branches/lbranches.png'),
      url('Branches/rbranches.png');
    background-position: left top, right top;
    background-repeat: repeat, repeat;
  }

  #home {
    background-size: 900px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 110%;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    font-size: 15px;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    display: none;
  }

  #projectSection {
    margin-top: 40px;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 35px;
    text-align: center;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 25px;
  }

  #text {
    margin-top: 50px;
    margin-left: 8%;
    margin-right: 8%;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
  }

  #textTab {
    margin-left: 8%;
    font-family: 'Karla', sans-serif;
    text-align: left;
    color: #ffffff;
  }

  #grid {
    margin-left: 8%;
  }
}

@media only screen and (min-width: 768px) {
  #name {
    padding-top: 170px;
    font-family: 'WindSong', cursive;
    font-size: 100px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 28px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 10px 10px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 30px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 20px;
    text-align: left;
    font-family: 'Karla', sans-serif;
    text-align: justify;
  }

  .branches {
    background-image: url('Branches/lbranches.png'),
      url('Branches/rbranches.png');
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1100px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 800px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    color: #fed7e2;
    display: inline;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1px;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0.8);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 40px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 30px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    font-size: 15px;
    color: #ffffff;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 992px) {
  #name {
    padding-top: 250px;
    font-family: 'WindSong', cursive;
    font-size: 100px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 30px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 15px 15px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 40px;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 22px;
    text-align: left;
    font-family: 'Karla', sans-serif;
  }

  .branches {
    background-image: url('Branches/lbranches.png'),
      url('Branches/rbranches.png');
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1500px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    background-size: 1100px;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    margin-top: 250px;
    color: #fed7e2;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 30px;
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 50px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 35px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
    font-size: 18px;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 1200px) {
  #name {
    padding-top: 270px;
    font-family: 'WindSong', cursive;
    font-size: 170px;
    text-shadow: 10px 10px 15px black;
    color: #fed7e2;
  }

  #subtitle {
    font-family: 'Major Mono Display', monospace;
    font-size: 40px;
    text-shadow: 5px 5px 5px black;
    color: #eebd95;
  }

  #projectBox {
    margin: 50px;
    padding-top: 50px;
    box-shadow: 15px 15px 10px black;
    text-align: 'center';
    background: rgba(57, 66, 80, 0.7);
    color: #ffffff;
    font-size: 20px;
  }

  #projectHeader {
    font-size: 2em;
    text-align: left;
    padding-bottom: 15px;
    font-family: 'Spectral', serif;
  }

  #projectText {
    font-size: 1.2em;
    text-align: left;
    font-family: 'Karla', sans-serif;
  }

  .branches {
    background-image: url('Branches/lbranches.png'),
      url('Branches/rbranches.png');
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
  }

  #home {
    background-size: 1950px;
    border-style: solid;
    border-width: 1px;
    border-color: #1c2736;
  }

  #projectPage {
    /* background-size: 1600px; */
    background-size: 78.5%;
  }

  .projectImage {
    border-style: solid;
    border-width: 1px;
    border-color: #fed7e2;
  }

  #button {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
  }

  #backButton {
    margin-left: 50px;
    padding-right: 10px;
    font-family: 'Karla', sans-serif;
  }

  #downButton {
    margin-top: 250px;
    color: #fed7e2;
  }

  #projectSection {
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 20%;
    padding-bottom: 30px;
    background: rgba(57, 66, 80, 0);
    text-align: center;
    font-size: 20px;
  }

  #projectTitle {
    font-family: 'Spectral', serif;
    color: #fed7e2;
    font-size: 60px;
  }

  #heading {
    margin-top: 50px;
    font-family: 'Spectral', serif;
    color: #ffffff;
    font-size: 40px;
  }

  #text {
    margin-top: 50px;
    font-family: 'Karla', sans-serif;
    text-align: justify;
    color: #ffffff;
    font-size: 20px;
  }

  #textTab {
    margin-left: 50px;
    font-family: 'Karla', sans-serif;
    color: #ffffff;
  }

  #grid {
    margin-left: 10%;
  }
}
